import { AppModules, RequestCategoryEnum } from '@smarttypes/core';

import { default as packageInfo } from '../../../../../package.json';

export interface IBaseEnv {
  releaseName: string;
  releasePhase: string;
  release: string;
  sentryDns?: string;
  guestByIntegration?: {
    name?: string;
    path?: string;
  };
  poweredBy: string | boolean;
  showUnlocked?: boolean;
  production: boolean;
  registrationEnabled: boolean;
  loginPageEnabled: boolean;
  externalLogin?: string;
  canEditRooms: boolean;
  userNameLogin: boolean;
  hasLeads: boolean;
  roomPreviewComponent: string;
  mediaLibrary: string;
  apiUrl: string;
  i18n?: {
    url?: string;
    type?: string;
    dev?: boolean;
    force?: boolean;
  };
  appViewUrl: string;
  gtmId?: string;
  appVersion?: string;
  jwt?: {
    allowedDomains?: string[];
    disallowedRoutes?: string[] | RegExp[];
  };
  intercomAppId?: string;
  intercomEnvironment?: string;
  allowExpiredAccounts?: boolean;
  googleKey?: string;
  requestsTypes?: RequestCategoryEnum[];
  massMessagingMaxRetryAttempts?: number;
  homepage: string;
  enableRouteSaving?: boolean;
  knowledgeBaseLinks?: {
    smartAutomation?: string;
  };
  modules?: {
    pushNotifications?: {
      enabled?: boolean;
    };
  };
  salesEmail?: string;
  ui: {
    user?: {
      login?: {
        showSelectWhatsApp?: boolean;
        forceLanguage?: boolean;
      };
    };
    hidePayments?: boolean;
    hideGuestAreaV1?: boolean;
    guestAreaConfiguredWelcomeModal?: boolean;
    canShowUpgradeArrow?: boolean;
    showAppsBadge?: boolean;
    forceApartmentType?: boolean; // TODO: Remove when NB switch to apartments
    flatRoomName?: boolean;
    top?: {
      enabled?: boolean;
      account?: boolean;
    };
    topNavigation?: {
      enabled?: boolean;
      modules?: AppModules[];
    };
    sidebar?: {
      enabled?: boolean;
      companySwitcher?: boolean;
    };
    unifiedInbox?: {
      header?: boolean;
      createRequest?: boolean;
      guests?: boolean;
      payments?: boolean;
      sendTemplate?: boolean;
      reservationStatus?: boolean;
      redirectConversation?: boolean;
      assignUser?: boolean;
    };
    qrMaterials?: {
      identification?: boolean;
      editLink?: boolean;
    };
    massMessaging?: {
      useWhatsAppConversationStarter?: boolean;
      filters?: {
        guests?: string[];
        status?: string[];
        tags?: boolean;
        nationality?: boolean;
        rooms?: boolean;
        building?: boolean;
        department?: boolean;
        guestAddDate?: boolean;
        marketingConsents?: boolean;
      };
    };
    reservation?: {
      showReservationId?: boolean;
    };
    requests?: {
      filters?: {
        category?: boolean;
        department?: boolean;
        commonName?: boolean;
        ident?: boolean;
      };
    };
    visitorsPlaceModule?: {
      enabled?: boolean;
      useAMPM?: boolean;
      meta?: boolean;
      allowPublish?: boolean;
    };
    visitorsAreaConfigurator?: {
      url?: boolean;
      workingHours?: boolean;
      qrCode?: boolean;
      previewUrl?: string | null;
    };
    rooms?: {
      edit?: boolean;
      remove?: boolean;
    };
    showVisitorRoomInfo?: boolean;
    guests?: {
      reservationStatus?: boolean;
      roomSwitcher?: boolean;
      multipleReservations?: boolean;
      hiddenFilters?: {
        rooms: boolean;
      };
      journeyDetails?: boolean;
      editable?: boolean;
      sendEntryCodes?: boolean;
      nationalityAsLastField?: boolean;
      forceActionButtonsInList?: boolean;
    };
    smartAutomation?: {
      forceCheckinEnable?: boolean;
      skipRoomCheck?: boolean;
      higherPlanRequired?: boolean;
      customModeWithoutRulesPlan?: boolean;
      settings?: {
        enable?: boolean;
        entryInstruction?: boolean;
        channels?: boolean;
      };
    };
    apartmentEditor?: {
      customSectionsEnable?: boolean;
      syncButton?: boolean;
      requiredFieldsWarning?: boolean;
      distribution?: string;
      importInformation?: boolean;
      copyFeature?: boolean;
      validation?: boolean;
      requiredFields?: string[];
      fields?: {
        base?: string[];
        stay?: string[];
      };
    };
    analytics?: {
      chartColor?: string;
    };
  };
  hideUnavailableModulesInMenu?: boolean;
  visitorsImportConfig?: {
    checkIntervalTime?: number;
    timeoutTime?: number;
    propertiesTemplate?: string;
    apartmentsAccountGuestsTemplate?: string;
    guestsAccountGuestTemplate?: string;
  };
  guestsEnabled?: boolean;
  requestsEnabled?: boolean;
  storageType?: Storage;
  smartChatDemoUrl?: string;
  appMobileUrl?: string;
  registerPushNotifications?: boolean;
  firebaseCreds?: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
}

export const BASE_ENV: IBaseEnv = {
  homepage: 'guest-area',
  enableRouteSaving: false,
  salesEmail: 'sales@mysmarthotel.com',
  ui: {
    user: {
      login: {
        showSelectWhatsApp: false,
      },
    },
    guestAreaConfiguredWelcomeModal: false,
    canShowUpgradeArrow: true,
    showAppsBadge: true,
    forceApartmentType: false,
    flatRoomName: false,
    top: {
      enabled: true,
      account: true,
    },
    sidebar: {
      enabled: true,
      companySwitcher: true,
    },
    topNavigation: {
      enabled: false,
    },
    unifiedInbox: {
      header: true,
      createRequest: true,
      guests: true,
      payments: true,
      sendTemplate: false,
      reservationStatus: false,
      redirectConversation: false,
      assignUser: true,
    },
    qrMaterials: {
      identification: true,
      editLink: true,
    },
    massMessaging: {
      filters: {
        status: ['arrivals', 'inHouse', 'checkedOut'],
        tags: true,
        nationality: true,
        rooms: false,
        guestAddDate: true,
        marketingConsents: true,
      },
    },
    requests: {
      filters: {
        category: true,
        department: false,
        commonName: false,
        ident: false,
      },
    },
    rooms: {
      edit: true,
      remove: true,
    },
    reservation: {
      showReservationId: true,
    },
    visitorsPlaceModule: {
      enabled: true,
      meta: false,
      allowPublish: false,
    },
    showVisitorRoomInfo: false,
    smartAutomation: {
      forceCheckinEnable: false,
      skipRoomCheck: false,
      higherPlanRequired: true,
      settings: {
        enable: true,
        entryInstruction: true,
        channels: true,
      },
    },
    guests: {
      reservationStatus: true,
      editable: true,
      journeyDetails: true,
      sendEntryCodes: true,
      forceActionButtonsInList: false,
      multipleReservations: true,
    },
    apartmentEditor: {
      customSectionsEnable: true,
      syncButton: true,
      requiredFieldsWarning: true,
      importInformation: true,
      copyFeature: true,
      validation: true,
      requiredFields: [
        'apartmentNumber',
        'floorNumber',
        'country',
        'postalCode',
        'city',
        'streetAndHouseNumber',
        'googleMapsPin',
        'checkInFromTime',
        'checkOutUntilTime',
        'wifiName',
        'entryInstruction',
      ],
      fields: {
        base: [
          'apartmentNumber',
          'floorNumber',
          'country',
          'postalCode',
          'city',
          'streetAndHouseNumber',
          'googleMapsPin',
          'checkInFromTime',
          'checkInToTime',
          'checkOutUntilTime',
          'wifiName',
          'wifiPassword',
        ],
        stay: ['about', 'directions', 'amenities', 'parking', 'transfer', 'meals', 'cleaning', 'website'],
      },
    },
  },
  releaseName: 'smart-chat',
  releasePhase: 'staging',
  release: 'COMMIT____SHA',
  poweredBy: '',
  userNameLogin: false,
  production: false,
  registrationEnabled: true,
  loginPageEnabled: true,
  canEditRooms: true,
  hasLeads: false,
  showUnlocked: true,
  roomPreviewComponent: '',
  mediaLibrary: 'https://develop.smartcore.mysmarthotel.co/api-router/media-library',
  apiUrl: 'https://develop.smartcore.mysmarthotel.co/api-router/',
  appViewUrl: 'https://develop.smartcore.mysmarthotel.co/app-views',
  appVersion: packageInfo.version,
  jwt: {
    allowedDomains: ['localhost:4200'],
    disallowedRoutes: [
      new RegExp(/api\/public/),
      new RegExp(/api\/account\/login/),
      new RegExp(/api\/account\/refresh/),
      new RegExp(/assets/),
    ],
  },
  allowExpiredAccounts: false,
  googleKey: 'AIzaSyCa96_7Az_eAMPgIrfxdW7QUodJXjuFfjU',
  i18n: {
    force: true,
  },
  requestsTypes: [
    RequestCategoryEnum.voucher,
    RequestCategoryEnum.housekeeping,
    RequestCategoryEnum.foodAndBeverages,
    RequestCategoryEnum.breakfast,
    RequestCategoryEnum.spa,
    RequestCategoryEnum.services,
    RequestCategoryEnum.maintenance,
    RequestCategoryEnum.standard,
    RequestCategoryEnum.activities,
    RequestCategoryEnum.feedback,
  ],
  massMessagingMaxRetryAttempts: 3,
  visitorsImportConfig: {
    checkIntervalTime: 5000,
    timeoutTime: 120000, // 2min
    propertiesTemplate: 'https://docs.google.com/spreadsheets/d/157BskIbBKt3fuw3qwQ1_C4bmv7wLrmNsOid2uWEjSvY',
    apartmentsAccountGuestsTemplate:
      'https://docs.google.com/spreadsheets/d/1lw8QN3gFY6rYO_ZqShoEJrWrQ2xvSrLbCbOWfkeKREA',
    guestsAccountGuestTemplate: 'https://docs.google.com/spreadsheets/d/1LpCL54vyhlsDxtqJoDvqlUlroGLbaBZUcMXm91kONFk',
  },
  knowledgeBaseLinks: {
    smartAutomation: 'https://docs.google.com/document/d/1huvpiZNg_6JXxZK9naAYjiA52yNdPjibF9DPm3DEYw8',
  },
  guestsEnabled: true,
  requestsEnabled: true,
  smartChatDemoUrl: 'http://localhost:8083',
};
